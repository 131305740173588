:root{
    --dark: #030409;
    --main-bg-color: #161617;
    --secondary-bg-color: #171A1F;
    --main-color: #EEF1EC;
    --secondary-color: #85A6A5;
    --error-color:#7000af;
    --max-width: 1280px;
    --default-margin: 2rem;
}
body {
    color-scheme: dark;
    background: var(--main-bg-color);
    color: var(--main-color);
}

a,
a:visited {
    color: var(--secondary-color);
}

a:hover {
    color: var(--main-color);
}

.error {
    color: var(--error-color);
}

.content {
    padding: var(--default-margin);
    background: linear-gradient(180deg, var(--secondary-bg-color), var(--main-bg-color));
}

img {
    width: 100%;
    max-width: var(--max-width);
}

input {
    margin: .5rem;
}


.hero {
    position: relative;
    background-color: var(--dark);
    filter: drop-shadow(2px 2px 5px var(--dark));
}

.hero img {
    object-fit: cover;
    max-width: none;
}

.hero > .copy {
    position: absolute;
    width: calc(100% - var(--default-margin) * 2);
    margin: var(--default-margin);
}

nav {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

nav ul a,
nav ul a:visited {
    text-decoration: none;
    padding: .7rem;
    background-color: var(--dark);
    color: var(--main-color);
    display: inline-block;
}

nav ul a:hover {
    color: var(--secondary-color);
}

nav .title {
    flex: 1;
}
nav .title a,
nav .title a:visited {
    color: var(--main-color);
    text-decoration: none;
}

nav .title img {
    max-height: 71px;
    float: left;
    object-fit: unset;
    width: auto;
    margin-top: -9px;
}

nav .title h1 {
    font-size: 1.5rem;
    margin: 0;
}

nav .title p {
    font-size: .65rem;
}